import { app } from "./js/app.js";
import { sliders } from "./js/sliders.js";
import { darkmode } from "./js/darkmode.js";
import { accesibility } from "./js/accesibility.js";

import 'slick-carousel';
import '@selectize/selectize';
import 'jquery';


import './scss/custom-bootstrap.scss'

import * as bootstrap from 'bootstrap';
import Masonry from 'masonry-layout';

import "./css/main.css";
import comunasRegiones from './json/comunas-regiones.json';

//import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";

window.$ = window.jQuery;


window.addEventListener("DOMContentLoaded", () => {

    app();
    sliders();
    darkmode();
    accesibility();

    const currentPath = window.location.pathname;
    // SEARCH
    const searchAllowedPaths = ["/titulos/", "/autor/", "/autores/", "/materias/", , "/materia/", "/coleccion/", "/colecciones/"];

    if (searchAllowedPaths.some(path => currentPath.startsWith(path))) {
        import("./js/search.js").then((searchComponent) => {
            searchComponent.initSearchComponent();
        });
    }

    // Contacto

    if (currentPath.startsWith("/contacto/")) {
        import("./js/contact.js").then((contactComponent) => {
            // Pasa comunasRegiones como parámetro a la función
            contactComponent.initContactComponent(comunasRegiones);
        });
    }

});


// See https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept("./app.js", (new_app) => {
        new_app?.app();
    });
}
